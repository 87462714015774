var cachedPromise;
function loadScript(src, namespace) {
  var existingElement = document.querySelector("[src=\"".concat(src, "\"]"));

  if (existingElement != null && cachedPromise !== undefined) {
    return cachedPromise;
  }

  if (existingElement != null && getNamespace(namespace) !== undefined) {
    return Promise.resolve(getNamespace(namespace));
  }

  var script = document.createElement('script');
  script.src = src;
  cachedPromise = new Promise(function (resolve, reject) {
    document.head.appendChild(script);
    window.addEventListener("TossPayments:initialize:".concat(namespace), function () {
      if (getNamespace(namespace) !== undefined) {
        resolve(getNamespace(namespace));
      } else {
        reject(new Error("[TossPayments SDK] Failed to load script: [".concat(src, "]")));
      }
    });
  });
  return cachedPromise;
}

function getNamespace(name) {
  return window[name];
}

var SCRIPT_URL = 'https://js.tosspayments.com/v1/payment-widget';

function loadPaymentWidget(clientKey, customerKey, options) {
  var loadOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var _loadOptions$src = loadOptions.src,
      src = _loadOptions$src === void 0 ? SCRIPT_URL : _loadOptions$src;

  if (typeof window === 'undefined') {
    // XXX(@HyunSeob): SSR할 때 생성자가 사용되는 경우 에러를 발생시키지 않는대신 정상적인 인스턴스를 반환하지 않는다.
    return Promise.resolve({});
  } // regenerator-runtime 의존성을 없애기 위해 `async` 키워드를 사용하지 않는다


  return loadScript(src, 'PaymentWidget').then(function (PaymentWidget) {
    return PaymentWidget(clientKey, customerKey, options);
  });
}

function clearPaymentWidget() {
  var _script$parentElement;

  var script = document.querySelector("script[src=\"".concat(SCRIPT_URL, "\"]"));
  script === null || script === void 0 ? void 0 : (_script$parentElement = script.parentElement) === null || _script$parentElement === void 0 ? void 0 : _script$parentElement.removeChild(script);
  window.PaymentWidget = undefined;
}

var ANONYMOUS = '@@ANONYMOUS';

export { ANONYMOUS, clearPaymentWidget, loadPaymentWidget };
